<template>
  <div class="add-hospital-admin">
    <h1>Add Hospital Administrator</h1>
    <AddHospitalAdmin :customer="2" @submit="create" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "@/router";
import AddHospitalAdmin from "@/containers/Hospital/AddHospitalAdmin";
import { getRoleType } from "@/helpers/helper";

export default {
  components: {
    AddHospitalAdmin
  },
  methods: {
    ...mapActions(["addHospitalAdmin"]),
    create(data) {
      const payload = {
        first_name: data.first_name,
        surname: data.surname,
        contact_phone: data.contact_phone,
        email: data.email,
        password: data.password,
        // 'dob': data.dob,
        hospital_name: data.hospital_name,
        // 'website': data.website ? data.website : "",
        user_name: data.email,
        role_type_id: getRoleType(),
        is_active: true,
        suspended: false,
        set_passwd_on_login: true
      };
      this.addHospitalAdmin(payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 50%;
    margin: auto;
  }
}
</style>
